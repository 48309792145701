<template>
  <!-- 代客加單的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="addOrderBySellerModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">代客加單</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- 取得顧客 -->
          <div class="mb-3">
            <p class="tw-border-start fw-bolder mb-3">選擇顧客</p>
            <p
              class="mb-3 text-break text-secondary"
              v-if="selectCustomer && selectCustomer.note"
            >
              <span class="fw-bolder">顧客備註:</span> {{ selectCustomer.note }}
            </p>
            <div v-if="!participants.loading">
              <!-- 搜尋顧客 -->
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="SearchCustomerForAddOrderBySeller"
                  placeholder="搜尋顧客"
                  v-model="searchCustomer"
                  :disabled="participants.data.length === 0"
                />
                <label for="SearchCustomer">搜尋顧客</label>
              </div>
              <!-- 顧客列表 -->
              <div
                class="p-2"
                v-if="participants.data.length > 0"
                style="height: 200px; overflow-y: scroll"
              >
                <template v-for="data in participantsList" :key="data.id">
                  <div
                    class="form-check p-0"
                    v-if="!data.deleted && data.verify && !data.user.masterId"
                  >
                    <input
                      class="form-check-input m-0 me-2"
                      type="radio"
                      name="selectCustomer"
                      :id="`SelectCustomerForAddOrderBySeller${data.id}`"
                      :value="data"
                      v-model="selectCustomer"
                    />
                    <label
                      class="form-check-label"
                      :for="`SelectCustomerForAddOrderBySeller${data.id}`"
                    >
                      {{ data.user.name }}
                      <span
                        class="badge bg-info text-dark"
                        v-if="data.nickName"
                        >{{ data.nickName }}</span
                      >
                    </label>
                  </div>
                </template>
              </div>
              <p class="text-danger fw-bolder mb-2" v-else>
                您的賣場尚未有任何顧客!
              </p>
              <!-- 建立虛擬顧客 -->
              <div class="fw-bolder">
                我要建立虛擬顧客:
                <input
                  class="me-2"
                  type="text"
                  placeholder="輸入姓名"
                  v-model="virtualUser"
                />
                <button
                  class="tw-btn tw-btn-success"
                  @click="createVirtualUser"
                >
                  建立
                </button>
              </div>
            </div>
            <AreaLoading v-else></AreaLoading>
          </div>
          <hr />
          <!-- 下單頁面 -->
          <div
            class="place-an-order"
            v-if="propsSelectInfos.data.length > 0"
            style="position: relative"
          >
            <!-- overlay -->
            <div :class="{ 'place-an-order-overlay': !selectCustomer }"></div>
            <!-- 可預購 -->
            <div v-if="propsSelectInfos.data[0].sellState === 'CANPREORDER'">
              <p class="tw-border-start fw-bolder mb-3">可預購</p>
              <!-- 主要款式 -->
              <div class="mb-3" v-if="canPreOrder.mainStyles.length > 0">
                <p class="fw-bolder mb-3">主要款式:</p>
                <div class="row">
                  <div
                    class="col-lg-3 col"
                    v-for="data in canPreOrder.mainStyles"
                    :key="data.id"
                  >
                    <button
                      @click="selectStyles('selectMain', data)"
                      :class="{ select: canPreOrder.selectMain === data.id }"
                    >
                      {{ data.name }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- 細項一 -->
              <div class="mb-3" v-if="canPreOrder.oneStyles.length > 0">
                <p class="fw-bolder mb-3">細項一:</p>
                <div class="row">
                  <div
                    class="col-lg-3 col"
                    v-for="data in canPreOrder.oneStyles"
                    :key="data.id"
                  >
                    <button
                      @click="selectStyles('selectOne', data)"
                      :class="{ select: canPreOrder.selectOne === data.id }"
                    >
                      {{ data.name }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- 細項二 -->
              <div class="mb-3" v-if="canPreOrder.twoStyles.length > 0">
                <p class="fw-bolder mb-3">細項二:</p>
                <div class="row">
                  <div
                    class="col-lg-3 col"
                    v-for="data in canPreOrder.twoStyles"
                    :key="data.id"
                  >
                    <button
                      @click="selectStyles('selectTwo', data)"
                      :class="{ select: canPreOrder.selectTwo === data.id }"
                    >
                      {{ data.name }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- 細項三 -->
              <div class="mb-3" v-if="canPreOrder.threeStyles.length > 0">
                <p class="fw-bolder mb-3">細項三:</p>
                <div class="row">
                  <div
                    class="col-lg-3 col"
                    v-for="data in canPreOrder.threeStyles"
                    :key="data.id"
                  >
                    <button
                      @click="selectStyles('selectThree', data)"
                      :class="{ select: canPreOrder.selectThree === data.id }"
                    >
                      {{ data.name }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- 細項四 -->
              <div class="mb-3" v-if="canPreOrder.fourStyles.length > 0">
                <p class="fw-bolder mb-3">細項四:</p>
                <div class="row">
                  <div
                    class="col-lg-3 col"
                    v-for="data in canPreOrder.fourStyles"
                    :key="data.id"
                  >
                    <button
                      @click="selectStyles('selectFour', data)"
                      :class="{ select: canPreOrder.selectFour === data.id }"
                    >
                      {{ data.name }}
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <!-- 總計 -->
              <div>
                <div class="row">
                  <!-- 數量 -->
                  <div class="col-md-4 col-6">
                    <div class="form-floating mb-3">
                      <input
                        onwheel="this.blur()"
                        type="number"
                        class="form-control"
                        id="CanPreOrderQuantityForAddOrderBySeller"
                        placeholder="數量"
                        min="1"
                        v-model="canPreOrderFinal.quantity"
                        pattern="[0-9]*"
                        oninput="this.value=this.value.replace(/\D/g,'')"
                      />
                      <label for="CanPreOrderQuantity">數量</label>
                    </div>
                  </div>
                  <!-- 金額 -->
                  <div class="col-md-4 col-6">
                    <div class="form-floating mb-3">
                      <input
                        onwheel="this.blur()"
                        type="number"
                        class="form-control"
                        id="CanPreOrderPriceForAddOrderBySeller"
                        placeholder="金額"
                        min="0"
                        v-model="canPreOrderFinal.price"
                        @input="
                          canPreOrderFinal.price = $methods.numberToFixed(
                            canPreOrderFinal.price
                          )
                        "
                      />
                      <label for="CanPreOrderPrice">金額</label>
                    </div>
                  </div>
                  <!-- 總計 -->
                  <div class="col-md-4 col">
                    <div class="form-floating mb-3">
                      <input
                        type="email"
                        class="form-control"
                        id="CanPreOrderTotalForAddOrderBySeller"
                        placeholder="總計"
                        :value="canPreOrderFinal.total"
                        disabled
                      />
                      <label for="CanPreOrderTotal">總計</label>
                    </div>
                  </div>
                  <!-- 備註 -->
                  <div class="col-12">
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        placeholder="備註"
                        id="CanPreOrderNoteForAddOrderBySeller"
                        style="height: 100px"
                        v-model="canPreOrderFinal.note"
                        maxlength="300"
                      ></textarea>
                      <label for="CanPreOrderNote">備註(300字)</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 僅供現貨 -->
            <div v-else-if="propsSelectInfos.data[0].sellState === 'STOCKONLY'">
              <p class="tw-border-start fw-bolder mb-3">僅供現貨</p>
              <template v-if="!stockOnly.loading">
                <div class="table-responsive" v-if="stockOnly.data">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th style="width: 200px; min-width: 200px">款式</th>
                        <th>現貨數</th>
                        <th>單價</th>
                        <th>數量</th>
                        <th>備註</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="data in stockOnly.data" :key="data[0]">
                        <tr
                          v-for="item in data[1].stockMap"
                          :key="item.stock.id"
                        >
                          <td>
                            <span v-for="style in item.styles" :key="style.id">
                              {{ style.name }}&ensp;
                            </span>
                          </td>
                          <td>
                            {{ item.stock.stockOnlyQuantity }}
                          </td>
                          <td>
                            {{ data[1].currentPrice }}
                          </td>
                          <td>
                            <input
                              onwheel="this.blur()"
                              type="number"
                              min="0"
                              v-model="item.stock.orderQuantity"
                              @input="listenOrderQuantity('stockOnly')"
                              pattern="[0-9]*"
                              oninput="this.value=this.value.replace(/\D/g,'')"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              maxlength="300"
                              v-model="item.stock.note"
                              placeholder="備註(300字)"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <p v-else class="fw-bolder text-danger">
                  此商品上未有任何庫存!
                </p>
              </template>
              <AreaLoading v-else></AreaLoading>
            </div>
            <!-- 限量 -->
            <div v-else-if="propsSelectInfos.data[0].sellState === 'LIMITED'">
              <p class="tw-border-start fw-bolder mb-3">限量</p>
              <template v-if="!limited.loading">
                <div class="table-responsive" v-if="limited.data">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th style="width: 200px; min-width: 200px">款式</th>
                        <th>限量數</th>
                        <th>單價</th>
                        <th>數量</th>
                        <th>備註</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="data in limited.data" :key="data[0]">
                        <tr
                          v-for="item in data[1].stockMap"
                          :key="item.stock.id"
                        >
                          <td>
                            <span v-for="style in item.styles" :key="style.id">
                              {{ style.name }}&ensp;
                            </span>
                          </td>
                          <td>
                            {{ item.stock.limitedQuantity }}
                          </td>
                          <td>
                            {{ data[1].currentPrice }}
                          </td>
                          <td>
                            <input
                              onwheel="this.blur()"
                              type="number"
                              min="0"
                              v-model="item.stock.orderQuantity"
                              @input="listenOrderQuantity('limited')"
                              pattern="[0-9]*"
                              oninput="this.value=this.value.replace(/\D/g,'')"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              maxlength="300"
                              v-model="item.stock.note"
                              placeholder="備註(300字)"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <p v-else class="fw-bolder text-danger">
                  此商品上未有任何庫存!
                </p>
              </template>
              <AreaLoading v-else></AreaLoading>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            關閉
          </button>
          <template v-if="propsSelectInfos.data.length > 0">
            <!-- 可預購 -->
            <div v-if="propsSelectInfos.data[0].sellState === 'CANPREORDER'">
              <button
                type="button"
                class="tw-btn tw-btn-success me-2"
                @click="createOrderForCanPreOrder('continue')"
              >
                持續購買
              </button>
              <button
                type="button"
                class="tw-btn tw-btn-success"
                @click="createOrderForCanPreOrder('once')"
              >
                確認
              </button>
            </div>
            <!-- 僅供現貨 -->
            <div v-else-if="propsSelectInfos.data[0].sellState === 'STOCKONLY'">
              <button
                type="button"
                class="tw-btn tw-btn-success"
                @click="createOrderForStockOnlyAndLimited('stockOnly')"
              >
                確認
              </button>
            </div>
            <!-- 限量 -->
            <div v-else-if="propsSelectInfos.data[0].sellState === 'LIMITED'">
              <button
                type="button"
                class="tw-btn tw-btn-success"
                @click="createOrderForStockOnlyAndLimited('limited')"
              >
                確認
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    participants: {
      type: Object,
      default: {
        loading: false,
        data: []
      }
    },
    propsSelectInfos: {
      type: Object,
      default: {
        show: 'name',
        data: []
      }
    },
    showAddOrderBySeller: {
      type: Boolean,
      default: false
    },
  },
  emits: ['notifyParentFromAddOrderBySeller'],
  data() {
    return {
      addOrderBySellerModal: {},
      participantsList: [],
      searchCustomer: '',
      selectCustomer: null,
      serverToken: null,
      storeId: 0,
      // 虛擬顧客
      virtualUser: '',
      // 可預購
      recordLastHierarchy: 0,
      canPreOrder: {
        mainStyles: [],
        oneStyles: [],
        twoStyles: [],
        threeStyles: [],
        fourStyles: [],
        selectMain: 0,
        selectOne: 0,
        selectTwo: 0,
        selectThree: 0,
        selectFour: 0,
      },
      canPreOrderFinal: {
        quantity: 1,
        price: 0,
        total: 0,
        note: '',
      },
      // 僅供現貨
      stockOnly: {
        loading: false,
        data: []
      },
      // 限量
      limited: {
        loading: false,
        data: []
      },
      // 判斷是否有做更改資料動作
      haveChangeData: false,
    }
  },
  created() {
    this.initialization()
  },
  mounted() {
    this.createModals(['addOrderBySellerModal'])
  },
  watch: {
    // 開啟 modal
    showAddOrderBySeller(val) {
      console.log(val)
      if (val === null) return
      if (val) {
        this.participantsList = JSON.parse(JSON.stringify(this.participants.data))
        this.searchCustomer = ''
        this.selectCustomer = null
        // 取得下單資料
        this.getOrderInfo(this.propsSelectInfos.data[0])
        this.haveChangeData = false
        this.addOrderBySellerModal.show()
      } else {
        this.addOrderBySellerModal.hide()
      }
    },
    // 搜尋顧客
    searchCustomer(val) {
      this.participantsList = this.participants.data.filter(item => {
        const string = `${item.user.name}${item.nickName ? item.nickName : ''}`
        return string.toLowerCase().match(val.toLowerCase())
      })
    },
    // 可預購 > 選主要款式
    'canPreOrder.selectMain'(val) {
      this.countFinalTotal()
    },
    // 選顧客
    selectCustomer(val) {
      if (val) {
        switch (this.propsSelectInfos.data[0].sellState) {
          case 'CANPREORDER':
            this.countFinalTotal()
            break;
          case 'STOCKONLY':
            this.listenOrderQuantity('stockOnly')
            break;
          case 'LIMITED':
            this.listenOrderQuantity('limited')
            break;
        }
      }
    },
    // 可預購 > 輸入數量
    'canPreOrderFinal.quantity'(val, oldVal) {
      console.log(val, oldVal)
      if (val !== oldVal) {
        this.countFinalTotal()
      }
    },
    // 可預購 > 輸入金額
    'canPreOrderFinal.price'(val, oldVal) {
      if (val !== oldVal) {
        const quantity = this.canPreOrderFinal.quantity ? this.canPreOrderFinal.quantity : 0
        const price = val ? val : 0
        this.canPreOrderFinal.total = this.$methods.numberToFixed(quantity * price)
      }
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 關閉 modal
    closeModal() {
      this.reloadByWindowsD4()
      this.$emit('notifyParentFromAddOrderBySeller', {
        showAddOrderBySeller: false,
        reloadDataTable: this.haveChangeData ? true : false
      })
    },
    // 新增虛擬顧客
    createVirtualUser() {
      this.$methods.switchLoading('show')
      const vm = this
      const createVirtualUserApi = `${process.env.VUE_APP_API}/participant/createVirtualUser`
      const header = {
        authorization: this.serverToken,
      }
      const data = new FormData()
      data.append('storeId', this.storeId)
      data.append('name', this.virtualUser)
      $.ajax({
        type: "POST",
        async: true,
        url: createVirtualUserApi,
        headers: header,
        data: data,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.virtualUser = ''
            vm.participants.data.push(res.data)
            vm.participantsList = JSON.parse(JSON.stringify(vm.participants.data))
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 整理下單資訊
    getOrderInfo(merchandiseInfo) {
      console.log(merchandiseInfo)
      if (merchandiseInfo.sellState === 'CANPREORDER') {
        // 可預購
        this.canPreOrder = {
          mainStyles: [],
          oneStyles: [],
          twoStyles: [],
          threeStyles: [],
          fourStyles: [],
          selectMain: 0,
          selectOne: 0,
          selectTwo: 0,
          selectThree: 0,
          selectFour: 0,
        }
        merchandiseInfo.merchandiseStyles.forEach(item => {
          if (!item.deleted) {
            if (item.hierarchy === 0) {
              this.canPreOrder.mainStyles.push(item)
            } else if (item.hierarchy === 1) {
              this.canPreOrder.oneStyles.push(item)
            } else if (item.hierarchy === 2) {
              this.canPreOrder.twoStyles.push(item)
            } else if (item.hierarchy === 3) {
              this.canPreOrder.threeStyles.push(item)
            } else if (item.hierarchy === 4) {
              this.canPreOrder.fourStyles.push(item)
            }
            this.recordLastHierarchy = item.hierarchy
          }
        })
        // 取得第一個主要款式 (未被刪除)
        const d4MainStyleIndex = _.findIndex(merchandiseInfo.merchandiseStyles, function(style) { return !style.deleted })
        // 給選取主要款式預設值
        this.canPreOrder.selectMain = merchandiseInfo.merchandiseStyles[d4MainStyleIndex].id
        this.canPreOrderFinal.quantity = 1
      } else if (merchandiseInfo.sellState === 'STOCKONLY') {
        // 僅供現貨 (需另外 call api 來取得庫存)
        this.stockOnly = {
          loading: true,
          data: []
        }
        this.getMerchandiseStyleStocks()
      } else {
        // 限量
        this.limited = {
          loading: true,
          data: []
        }
        this.getMerchandiseStyleStocks()
      }
    },
    // 可預購 > 選擇到的款式
    selectStyles(whichOne, data) {
      this.canPreOrder[whichOne] = data.id
    },
    // 可預購 > 計算總值
    countFinalTotal() {
      this.canPreOrder.mainStyles.some(item => {
        if (this.canPreOrder.selectMain === item.id) {
          if (this.selectCustomer) {
            console.log('has customer')
            const customerVipId = this.selectCustomer.storeVipId ? this.selectCustomer.storeVipId : 0
            let currentPrice = item.price
            this.canPreOrderFinal.price = currentPrice
            item.merchandiseDiscounts.forEach(discount => {
              const discountVipId = discount.storeVipId ? discount.storeVipId : 0
              const minQuantity = discount.minQuantity
              const maxQuantity = discount.maxQuantity
              let inPurchaseInterval = false
              if (minQuantity && maxQuantity) inPurchaseInterval = minQuantity <= this.canPreOrderFinal.quantity && this.canPreOrderFinal.quantity <= maxQuantity
              else inPurchaseInterval = minQuantity <= this.canPreOrderFinal.quantity
              const idVipInterval = customerVipId >= discountVipId
              if (inPurchaseInterval && idVipInterval && discount.price < currentPrice) currentPrice = discount.price
              this.canPreOrderFinal.price = currentPrice
            })
          } else {
            console.log('hasnt customer')
            this.canPreOrderFinal.price = item.price
          }
          this.canPreOrderFinal.total = this.$methods.numberToFixed(this.canPreOrderFinal.price * this.canPreOrderFinal.quantity)
          return true
        }
      })
    },
    // 可預購 > 建立訂單
    createOrderForCanPreOrder:_.debounce(function(status) {
      const vm = this;
      const createOrderForCanPreOrderApi = `${process.env.VUE_APP_API}/merchOrder/create`;
      const header = {
        authorization: this.serverToken
      };
      if (!this.selectCustomer) return this.SweetAlert('other', '您尚未選擇顧客')
      if (!this.canPreOrderFinal.quantity) return this.SweetAlert('other', '您的數量必須大於 0')
      if (this.canPreOrderFinal.price === '') return this.SweetAlert('other', '您的金額尚未填寫')
      let merchandiseStyleIds = []
      switch (this.recordLastHierarchy) {
        case 0:
          if (!this.canPreOrder.selectMain) return this.SweetAlert('other', '您尚未選擇主要款式')
          merchandiseStyleIds.push(this.canPreOrder.selectMain)
          break;
        case 1:
          if (!this.canPreOrder.selectMain) return this.SweetAlert('other', '您尚未選擇主要款式')
          if (!this.canPreOrder.selectOne) return this.SweetAlert('other', '您尚未選擇細項一')
          merchandiseStyleIds = [this.canPreOrder.selectMain, this.canPreOrder.selectOne]
          break;
        case 2:
          if (!this.canPreOrder.selectMain) return this.SweetAlert('other', '您尚未選擇主要款式')
          if (!this.canPreOrder.selectOne) return this.SweetAlert('other', '您尚未選擇細項一')
          if (!this.canPreOrder.selectTwo) return this.SweetAlert('other', '您尚未選擇細項二')
          merchandiseStyleIds = [this.canPreOrder.selectMain, this.canPreOrder.selectOne, this.canPreOrder.selectTwo]
          break;
        case 3:
          if (!this.canPreOrder.selectMain) return this.SweetAlert('other', '您尚未選擇主要款式')
          if (!this.canPreOrder.selectOne) return this.SweetAlert('other', '您尚未選擇細項一')
          if (!this.canPreOrder.selectTwo) return this.SweetAlert('other', '您尚未選擇細項二')
          if (!this.canPreOrder.selectThree) return this.SweetAlert('other', '您尚未選擇細項三')
          merchandiseStyleIds = [this.canPreOrder.selectMain, this.canPreOrder.selectOne, this.canPreOrder.selectTwo, this.canPreOrder.selectThree]
          break;
        case 4:
          if (!this.canPreOrder.selectMain) return this.SweetAlert('other', '您尚未選擇主要款式')
          if (!this.canPreOrder.selectOne) return this.SweetAlert('other', '您尚未選擇細項一')
          if (!this.canPreOrder.selectTwo) return this.SweetAlert('other', '您尚未選擇細項二')
          if (!this.canPreOrder.selectThree) return this.SweetAlert('other', '您尚未選擇細項三')
          if (!this.canPreOrder.selectFour) return this.SweetAlert('other', '您尚未選擇細項四')
          merchandiseStyleIds = [this.canPreOrder.selectMain, this.canPreOrder.selectOne, this.canPreOrder.selectTwo, this.canPreOrder.selectThree, this.canPreOrder.selectFour]
          break;
      }
      this.$methods.switchLoading('show')
      const data = [{
        merchOrder: {
          storeId: this.$route.params.storeId,
          merchandiseId: this.propsSelectInfos.data[0].id,
          buyerId: this.selectCustomer.id,
          price: this.canPreOrderFinal.price,
          quantity: this.canPreOrderFinal.quantity,
          note: this.canPreOrderFinal.note
        },
        merchandiseStyleIds: merchandiseStyleIds
      }]
      console.log(data)
      $.ajax({
        type: "POST",
        async: true,
        url: createOrderForCanPreOrderApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            if (status === 'once') {
              vm.$methods.switchLoading('hide')
              vm.$emit('notifyParentFromAddOrderBySeller', {
                showAddOrderBySeller: false,
                reloadDataTable: true
              })
              vm.reloadByWindowsD4()
            } else if (status === 'continue') {
              vm.canPreOrder.selectMain = vm.canPreOrder.mainStyles[0].id
              vm.canPreOrder.selectOne = 0
              vm.canPreOrder.selectTwo = 0
              vm.canPreOrder.selectThree = 0
              vm.canPreOrder.selectFour = 0
              vm.canPreOrderFinal.quantity = 1 
              vm.canPreOrderFinal.note = ''
              vm.$methods.switchLoading('hide')
              vm.haveChangeData = true
              vm.SweetAlert(res.code, res.message)
              vm.$emit('notifyParentFromAddOrderBySeller', {
                showAddOrderBySeller: null,
                reloadDataTable: false
              })
            }
          } else {
            vm.SweetAlert(res.code, res.message)
            vm.$methods.switchLoading('hide')
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      });
    }, 2000, {
      leading: true, // 在延遲開始前，立即調用
      trailing: false, // 在延遲結束前，保證不再調用
    }),
    // 僅供現貨 / 限量 > 取資料
    getMerchandiseStyleStocks() {
      const vm = this
      const getMerchandiseStyleStocksApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 16,
          ids: [this.propsSelectInfos.data[0].id],
          methods: '{generateMerchandiseStylesStockJsonNode}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandiseStyleStocksApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseInfo = res.data[0].objects[0]
            let stylesMap = new Map()
            merchandiseInfo.merchandiseStylesStockJsonNode.forEach(item => {
              // 檢查款式是否有被刪除
              const stylesHasDeleted = item.styles.some(style => {
                return style.deleted
              })
              if (!stylesHasDeleted && item.stock && item.stock.stockOnlyQuantity > 0 && merchandiseInfo.sellState === 'STOCKONLY') {
                // * 僅供現貨
                // 多紀錄下單數量和備註
                item.stock.orderQuantity = 0
                item.stock.note = ''
                if (!stylesMap.has(item.styles[0].id)) {
                  const mainStyle = vm.propsSelectInfos.data[0].merchandiseStyles.filter(mainStyle => {
                    return mainStyle.id === item.styles[0].id 
                  })
                  const object = {
                    stockMap: [{
                      stock: JSON.parse(JSON.stringify(item.stock)),
                      styles: JSON.parse(JSON.stringify(item.styles)),
                    }],
                    mainStyle: JSON.parse(JSON.stringify(mainStyle)),
                    currentPrice: item.styles[0].price
                  }
                  stylesMap.set(item.styles[0].id, object)
                } else {
                  const object = {
                    stock: JSON.parse(JSON.stringify(item.stock)),
                    styles: JSON.parse(JSON.stringify(item.styles))
                  }
                  stylesMap.get(item.styles[0].id).stockMap.push(object)
                }
              } else if (!stylesHasDeleted && item.stock && item.stock.limitedQuantity > 0 && merchandiseInfo.sellState === 'LIMITED') {
                // * 限量
                // 多紀錄下單數量和備註
                item.stock.orderQuantity = 0
                item.stock.note = ''
                if (!stylesMap.has(item.styles[0].id)) {
                  const mainStyle = vm.propsSelectInfos.data[0].merchandiseStyles.filter(mainStyle => {
                    return mainStyle.id === item.styles[0].id 
                  })
                  // 原本給的限量數還要再扣掉訂單數才是真正要顯示給顧客的值，這裡算完直接取代原本限量數的位置
                  item.stock.limitedQuantity = Math.max(item.stock.limitedQuantity - item.stock.merchOrderQuantity, 0)
                  const object = {
                    stockMap: [{
                      stock: JSON.parse(JSON.stringify(item.stock)),
                      styles: JSON.parse(JSON.stringify(item.styles)),
                    }],
                    mainStyle: JSON.parse(JSON.stringify(mainStyle)),
                    currentPrice: item.styles[0].price
                  }
                  stylesMap.set(item.styles[0].id, object)
                } else {
                  // 原本給的限量數還要再扣掉訂單數才是真正要顯示給顧客的值，這裡算完直接取代原本限量數的位置
                  item.stock.limitedQuantity = Math.max(item.stock.limitedQuantity - item.stock.merchOrderQuantity, 0)
                  const object = {
                    stock: JSON.parse(JSON.stringify(item.stock)),
                    styles: JSON.parse(JSON.stringify(item.styles))
                  }
                  stylesMap.get(item.styles[0].id).stockMap.push(object)
                }
              }
            })
            console.log(stylesMap)
            if (stylesMap.size > 0) {
              if (merchandiseInfo.sellState === 'STOCKONLY') {
                vm.stockOnly = {
                  loading: false,
                  data: stylesMap
                }
              } else if (merchandiseInfo.sellState === 'LIMITED') {
                vm.limited = {
                  loading: false,
                  data: stylesMap
                }
              }
            } else {
              vm.stockOnly = {
                loading: false,
                data: []
              }
              vm.limited = {
                loading: false,
                data: []
              }
            }
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 僅供現貨 / 限量 > 監聽數量
    listenOrderQuantity(status) {
      const customerVipId = this.selectCustomer.storeVipId ? this.selectCustomer.storeVipId : 0
      if (status === 'stockOnly') {
        for (const [key, value] of this.stockOnly.data) {
          console.log(key, value)
          let totalQuantity = 0
          value.currentPrice = value.mainStyle[0].price
          value.stockMap.forEach(item => {
            let quantity = 0
            if (item.stock.orderQuantity > item.stock.stockOnlyQuantity) item.stock.orderQuantity = item.stock.stockOnlyQuantity
            item.stock.orderQuantity === '' ? quantity = 0 : quantity = item.stock.orderQuantity
            totalQuantity += quantity
          })
          value.mainStyle[0].merchandiseDiscounts.forEach(discount => {
            const minQuantity = discount.minQuantity
            const maxQuantity = discount.maxQuantity
            const styleVipId = discount.storeVipId ? discount.storeVipId : 0
            let inPurchaseInterval = false
            if (minQuantity && maxQuantity) inPurchaseInterval = minQuantity <= totalQuantity && totalQuantity <= maxQuantity
            else inPurchaseInterval = minQuantity <= totalQuantity
            const idVipInterval = customerVipId >= styleVipId
            if (inPurchaseInterval && idVipInterval && value.currentPrice > discount.price) value.currentPrice = discount.price
          })
        }
      } else {
        for (const [key, value] of this.limited.data) {
          console.log(key, value)
          let totalQuantity = 0
          value.currentPrice = value.mainStyle[0].price
          value.stockMap.forEach(item => {
            let quantity = 0
            if (item.stock.orderQuantity > item.stock.limitedQuantity) item.stock.orderQuantity = item.stock.limitedQuantity
            item.stock.orderQuantity === '' ? quantity = 0 : quantity = item.stock.orderQuantity
            totalQuantity += quantity
          })
          value.mainStyle[0].merchandiseDiscounts.forEach(discount => {
            const minQuantity = discount.minQuantity
            const maxQuantity = discount.maxQuantity
            const styleVipId = discount.storeVipId ? discount.storeVipId : 0
            let inPurchaseInterval = false
            if (minQuantity && maxQuantity) inPurchaseInterval = minQuantity <= totalQuantity && totalQuantity <= maxQuantity
            else inPurchaseInterval = minQuantity <= totalQuantity
            const idVipInterval = customerVipId >= styleVipId
            if (inPurchaseInterval && idVipInterval && value.currentPrice > discount.price) value.currentPrice = discount.price
          })
        }
      }
    },
    // 僅供現貨 / 限量 > 建立訂單
    createOrderForStockOnlyAndLimited:_.debounce(function(status) {
      const vm = this;
      const createOrderApi = `${process.env.VUE_APP_API}/merchOrder/create`;
      const header = {
        authorization: this.serverToken
      };
      if (!this.selectCustomer) return this.SweetAlert('other', '您尚未選擇顧客')
      let data = []
      let map = []
      if (status === 'stockOnly') map = this.stockOnly.data
      else map = this.limited.data
      for (const [key, value] of map) {
        value.stockMap.forEach(item => {
          if (item.stock.orderQuantity !== '' && item.stock.orderQuantity > 0) {
            const object = {
              merchOrder: {
                storeId: this.$route.params.storeId,
                merchandiseId: this.propsSelectInfos.data[0].id,
                buyerId: this.selectCustomer.id,
                price: value.currentPrice,
                quantity: item.stock.orderQuantity,
                note: item.stock.note
              },
              merchandiseStyleIds: []
            }
            item.styles.forEach(style => {
              object.merchandiseStyleIds.push(style.id)
            })
            data.push(object)
          }
        })
      }
      if (data.length === 0) return this.SweetAlert('other', '您尚未輸入任何數量或數量輸入有負數')
      this.$methods.switchLoading('show')
      console.log(data)
      $.ajax({
        type: "POST",
        async: true,
        url: createOrderApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.$methods.switchLoading('hide')
            vm.$emit('notifyParentFromAddOrderBySeller', {
              showAddOrderBySeller: false,
              reloadDataTable: true
            })
            vm.reloadByWindowsD4()
          } else {
            vm.SweetAlert(res.code, res.message)
            vm.$methods.switchLoading('hide')
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      });
    }, 2000, {
      leading: true, // 在延遲開始前，立即調用
      trailing: false, // 在延遲結束前，保證不再調用
    }),
    // 若是在「商品詳細資訊」內的「FB留言訂單」就直接重新整理
    reloadByWindowsD4() {
      if (this.$route.name === 'Orders') {
        location.reload()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.place-an-order {
  .place-an-order-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  button {
    min-width: 100px;
    white-space: normal; // 對中文字
    word-break: break-all; // 只對英文字且依照字母斷行
    margin-bottom: 10px;
    background: rgb(235, 235, 235);
    border-radius: 10px;
    padding: 5px;
    &.select {
      background: rgb(252, 228, 161);
    }
  }
}
</style>